.menu-container {
    width: 80%;
    margin: 0 auto;
}
.menu-container h4{
    font-weight: 900;
}
.menu-container h4{
    font-weight: 900;
}
.menu-container p{
    margin: 0;
}

hr {
    border: 3px solid var(--clr-red);
}

.top-hr {
    margin-top: 3%;
}

.bold {
    font-weight: 900;
    text-transform: capitalize;
}

table {
    margin-top: 3%;
    width: 100%;
}
.pricing {
    width: 10%;
    text-align: center;
}


.title {
    width: 25%;
    text-align: start;
}


.menu-accent {
    display: flex;
    color: var(--clr-red);
    text-transform: uppercase;
    font-weight: 900;
    
    
}

.menu-bold {
    text-transform: uppercase;
    font-weight: 900;
}

.toppings, .sauces  {
    padding-top: 3%;
    display: flex;
    justify-content: center;
    h5 {
        padding-right: .5%;
    }
}

.menu-title{
    display: flex;
    justify-content: center;
}

.charges {
    padding: 3%;
  
    text-align: center;
    h5 {
        padding-right: .5%;
    }
}

.specials-desc {
    display: flex;
    justify-self: start;
    
}

.menu-btn {
    display: flex;
    justify-content: center;
    padding-bottom: 3%;
}



@media screen and (max-width: 450px) {
   
    .menu-container h4 {
        font-size: calc(6px + 0.690625vw);
    }
}