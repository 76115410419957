.nav-links-container {
    background-color: var(--clr-blue);
    display: flex;
    justify-content: center;
}

.nav-links {
    background-color: var(--clr-blue);
    
    
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    
    
    height: 50px;
}

.link-btn {
    background-color: transparent;
    border: 0;
    height: 50px;
    margin: 0;
    padding: 0 20px;
    cursor: pointer;   
    
}
.link-btn:hover  h3{
    color: var(--clr-red);
}
.active .link-btn  {
    background-color: var(--clr-red);
   
}
.active .link-btn:hover h3 {
    color: var(--clr-white);
}

.facebook-link {
    color: var(--clr-white);
    height: 40px;
    padding: 5px 20px;
}

.facebook-link:hover {
    color: var(--clr-red);
}

.phone-link {
    color: var(--clr-white);
    height: 40px;
    padding: 5px 0;
}

.phone-link:hover {
    color: var(--clr-red);
}

// for <450px screen displays dont display nav links
@media screen and (max-width: 450px) {
    .link-btn {
        padding:  0 5px;
    }
    .facebook-link {
        padding: 5px 5px;
        
    }
    
}
