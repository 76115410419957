.hero-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.hero {
    display: flex;
    width: 60%;
    align-items: center;
    margin: 30px 0;
}

.hero-content {
    width: 100%;
    display: block;
    margin: 0 3%;
}



.accent {
    color: var(--clr-red);
}

.btn {
    cursor: pointer;
    border-radius: var(--radius);
    box-shadow: var(--shadow-soft);
    padding: 10px;
    color: var(--clr-white);
    background-color: var(--clr-red);
    border-style: none;
}
.btn:hover {
    background-color: var(--clr-blue);
}

.hero-img {
    background-color: var(--clr-red);
    border-radius: var(--radius);
    padding: 2.5%;
    width: 80%;
    
    box-shadow: var(--shadow-soft);
}

.hero-img img{
    border-radius: var(--radius);
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow);
}

.categories-container {
    background-color: var(--clr-blue);
    border-radius: var(--radius);
    box-shadow: var(--shadow);
    display: block;
    
}

.categories-container h1 {
    width: 60%;
    padding: 15px 0 5px 0;
    color: var(--clr-white);
    margin: auto;
    text-align: center;
    border-bottom: 3px solid var(--clr-red);
}

.categories {
    background-color: var(--clr-blue);
    padding-bottom: 15px;
    width: 90%;
    display: flex;
    margin: auto;
    justify-content: center;
}

.category-card-container {
    background-color: var(--clr-red);
    width: 100%;
    border-radius: var(--radius);
    margin: 20px;
    box-shadow: var(--shadow);
}

.category-card {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: var(--clr-red);
    border-radius: var(--radius);
    
    
    margin: 7%;
    box-shadow: var(--shadow);
}

.category-card:hover h2 {
    
    text-shadow: 1px 1px 15px #0303037d;
   
}
.category-card:hover {
    box-shadow: var(--halo);
   
    cursor: pointer;
}

.category-card img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: var(--radius);
}

.category-card h2 {
    position: absolute;
    text-align: center;
    color: var(--clr-white);
    background-color: var(--clr-transparent);
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    padding: 5px 0;
    margin: 0;
    width: 100%;
}

.directions-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.directions {
    display: flex;
    width: 60%;
    align-items: center;
    margin: 30px 0;
}

.directions-content {
    width: 100%;
    display: block;
    margin: 0 3%;
}

.directions-img {
    background-color: var(--clr-red);
    border-radius: var(--radius);
    padding: 2.5%;
    width: 80%;
    box-shadow: var(--shadow-soft);
}

.directions-img img{
    border-radius: var(--radius);
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow);
}

a {
    color: inherit;
    text-decoration: none;
}



// for <800px screen displays dont display nav links
@media screen and (max-width: 800px) {
    .categories {
        
        
        margin: auto;
        display: inline-block;
        
        border-radius: var(--radius);
        width: 100%;
    }
    .category-card-container {
        width: 60%;
        display: block;
        margin: 20px auto;
        padding: .01% 0;
        
    }
    .category-card {
        margin: 6%;
        
    }
    .hero {
        width: 80%;
    }
}
// for <400px screen displays dont display nav links
@media screen and (max-width: 400px) {
   
    .hero {
        width: 90%;
    }
}
