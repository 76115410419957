@import url('https://fonts.googleapis.com/css2?family=Schibsted+Grotesk&display=swap');
:root {
  //colors
  --clr-dark-grey: #292929;
  --clr-grey: #D9D9D9;
  --clr-white: #F0F0F0;
  --clr-blue: #234582;
  --clr-red: #AE3033;
  --clr-black: #0B0B0B;
  --clr-transparent: #0b0b0ba2;
  // fonts
  --font-primary:  'Schibsted Grotesk', sans-serif;
  // shadows
  --halo: 0px 0px 20px 0px #ffffff53;
  --shadow: 0px 0px 15px 0px #00000075;
  --shadow-soft: 0px 0px 15px 0px #00000043;
  --shadow-top: 0px 20px 30px 0px #00000075;
  // extras
  --radius: 10px;
}

html {
  width: 100%;
}
body {
  padding: 0;
  margin: 0;
  background-color: var(--clr-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: var(--font-primary);
  /* font-size: 20px; */
  font-size: calc(18px + 0.690625vw);
  font-weight: 600;
  letter-spacing: 1.5px;
  margin: 0;
  
}

h2 {
  font-family: var(--font-primary);
  /* font-size: 20px; */
  font-size: calc(10px + 0.690625vw);
  font-weight: 600;
  letter-spacing: 1.5px;
  margin: 0;
  text-transform: capitalize;
}

h3 {
  font-family: var(--font-primary);
  /* font-size: 20px; */
  font-size: calc(10px + 0.690625vw);
  font-weight: 600;
  margin: 0;
  color: var(--clr-white);
  letter-spacing: 2px;
  text-transform: capitalize;
}

h4 {
  font-family: var(--font-primary);
  /* font-size: 20px; */
  font-size: calc(10px + 0.690625vw);
  font-weight: 900;
  margin: 0;
  
  letter-spacing: 2px;
  text-transform: capitalize;
}

h5 {
  font-family: var(--font-primary);
  /* font-size: 20px; */
  font-size: calc(7px + 0.690625vw);
  font-weight: 600;
  margin: 0;
  
  letter-spacing: 2px;
  text-transform: capitalize;
}

p {
  font-family: var(--font-primary);
  /* font-size: 20px; */
  font-size: calc(6px + 0.690625vw);
  font-weight: 300;
  
  color: var(--clr-black);
  letter-spacing: .8px;
}

li {
  list-style: none;
  
}


