.footer-container {
    background-color: var(--clr-blue);
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    box-shadow: var(--shadow);
}

.hours-container {
    padding: 10px 0;
    text-align: center;
    
    color: var(--clr-white);
}

.hours-container h1 {
    text-transform: capitalize;
    text-align: center;
}

.hours-container p {
    color: var(--clr-white);
    
    width: 25%;
}

.hours-container h4 {
    width: 25%;
    
}

.hours {
    padding-left: 0px;
    width: 100%;
    margin: auto 0;
}

.hours li {
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.footer {
    border-top:3px solid var(--clr-red);
    border-bottom:3px solid var(--clr-red);
    display: flex;
    justify-content: center;
   
    
}



// for <400px screen displays dont display nav links
@media screen and (max-width: 400px) {
    .hours-container p {
        width: 35%;
    }
    
    .hours-container h4 {
        width: 35%;
    }
    
}