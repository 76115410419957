.nav-container {
    align-self: center;
    width: 100%;
}

.logo {
    background: linear-gradient(90deg, var(--clr-blue) 50%, var(--clr-red) 50%);;
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo img{
    height: 200px;
    width: 517px;
}

.navbar-links {
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    border-top:3px solid var(--clr-red);
}

.navbar-links .nav-links-container {
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    box-shadow: 0px 15px 15px -15px #00000075;
}
// for <600px screen displays dont display nav links
@media screen and (max-width: 600px) {
    .logo img{
        height: 150px;
        width: 95%;
    }
}

